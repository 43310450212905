<template>
  <section class="testimonials text-center bg-light">
    <div class="container page-section">
      <b-card :title="$t('equipe.equipe')">
        <hr class="my-4" />
        <h2>
          {{ $t("equipe.professores") }}
        </h2>
        <hr class="divider my-4" />
        <div class="row">
          <div
            class="col-lg-3 col-md-4 col-sm-6 col-xs-6 p-3"
            v-for="prof in professors"
            :key="prof.email"
          >
            <div class="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                v-if="prof.sexo == 'female'"
                class="img-fluid rounded-circle mb-3"
                src="@/assets/img/prof_female.svg"
                alt=""
              />
              <img
                v-else
                class="img-fluid rounded-circle mb-3"
                src="@/assets/img/prof_male.svg"
                alt=""
              />
              <h5>{{ prof.name }}</h5>
              <a href="#" class="font-weight-light mb-0">{{ prof.email }}</a>
            </div>
          </div>
        </div>
        <hr class="divider my-4" />
        <h2>
          {{ $t("equipe.desenvolvedores") }}
        </h2>
        <hr class="divider my-4" />
        <div class="row">
          <div
            class="col-lg-3 p-3"
            v-for="(dev, index) in developers"
            :key="index"
          >
            <div class="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                class="img-fluid rounded-circle mb-3"
                :src="require(`../assets/img/${dev.img}.svg`)"
                alt=""
              />
              <h5>{{ dev.name }}</h5>
              <a :href="dev.github" class="font-weight-light mb-0">
                github
              </a>
              <!-- <div class="col-lg-12 h-100 text-center text-lg-center my-auto">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item mr-3">
                    <a href="#">
                      <b-icon-controller
                        class="card-list-category-item-icon"
                        style="color:#007bff"
                      />
                    </a>
                  </li>
                  <li class="list-inline-item mr-3">
                    <a href="#">
                      <b-icon-lightning-fill
                        class="card-list-category-item-icon"
                        style="color:#007bff"
                      />
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="#">
                      <b-icon-phone
                        class="card-list-category-item-icon"
                        style="color:#007bff"
                      />
                    </a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      developers: [
        {
          name: "Allisson Néo",
          description: "",
          email: "allissonneo@gmail.com",
          github: "https://github.com/allissonneo",
          linkedin: "https://www.linkedin.com/in/allisson-n%C3%A9o-980182198/",
          img: "generic_dev"
        },
        {
          name: "Isaias Santos",
          description: "",
          email: "",
          github: "https://github.com/isaiassantossilva",
          linkedin: "",
          img: "generic_dev"
        },
        {
          name: "Igor Pereira",
          description: "",
          email: "",
          github: "https://github.com/IgorPLima",
          linkedin: "",
          img: "generic_dev"
        },
        {
          name: "Fernando Éricles",
          description: "",
          email: "",
          github: "https://github.com/desconexo",
          linkedin: "",
          img: "generic_dev"
        },
        {
          name: "Guilherme Oliveira",
          description: "",
          email: "",
          github: "https://github.com/GuilhermeMO",
          linkedin: "",
          img: "generic_dev"
        },
        {
          name: "Igor Galdino",
          description: "",
          email: "",
          github: "https://github.com/IgorGaldino",
          linkedin: "",
          img: "igor"
        },
        {
          name: "Ivyson Lucas",
          description: "",
          email: "",
          github: "https://github.com/ivyson10",
          linkedin: "",
          img: "generic_dev"
        },
        {
          name: "Jaciana Barbosa",
          description: "",
          email: "",
          github: "https://github.com/jacianabarbosa",
          linkedin: "",
          img: "generic_dev_female"
        },
        {
          name: "José Wellington",
          description: "",
          email: "",
          github: "https://github.com/jwellington58",
          linkedin: "",
          img: "wellington"
        },
        {
          name: "Karol Sena",
          description: "",
          email: "",
          github: "",
          linkedin: "",
          img: "generic_dev_female"
        },
        {
          name: "Luan Douglas",
          description: "",
          email: "",
          github: "https://github.com/luandouglas",
          linkedin: "",
          img: "luan"
        },
        {
          name: "Marcos Antônio",
          description: "",
          email: "",
          github: "https://github.com/marcosantoniofilho16",
          linkedin: "",
          img: "marcos"
        },
        {
          name: "Matheus Gurgel",
          description: "",
          email: "",
          github: "https://github.com/MatheusTF7",
          linkedin: "",
          img: "matheus"
        },

        {
          name: "Ruan Gondim",
          description: "",
          email: "",
          github: "https://github.com/ruansantos0",
          linkedin: "",
          img: "ruan"
        },
        {
          name: "Werlesson Oliveira",
          description: "",
          email: "",
          github: "https://github.com/werlesson",
          linkedin: "",
          img: "werlesson"
        }
      ],
      professors: [
        {
          name: "Alexandro Damasceno",
          description: "",
          email: "alexandro@lar.ifce.edu.br",
          github: "",
          linkedin: "",
          sexo: "male"
        },
        {
          name: "Carina Teixeira",
          description: "",
          email: "carina@lar.ifce.edu.br",
          github: "",
          linkedin: "",
          sexo: "female"
        },
        {
          name: "Mauro Oliveira",
          description: "",
          email: "mauro@lar.ifce.edu.br",
          github: "",
          linkedin: "",
          sexo: "male"
        },
        {
          name: "Reinaldo Braga",
          description: "",
          email: "reinaldo@lar.ifce.edu.br",
          github: "",
          linkedin: "",
          sexo: "male"
        },
        {
          name: "Silas Santiago",
          description: "",
          email: "",
          github: "",
          linkedin: "",
          sexo: "male"
        },
        {
          name: "Thiago Felippe",
          description: "",
          email: "thiago@lar.ifce.edu.br",
          github: "",
          linkedin: "",
          sexo: "male"
        },
        {
          name: "Wally Menezes",
          description: "",
          email: "wally@ifce.edu.br",
          github: "",
          linkedin: "",
          sexo: "male"
        },
        {
          name: "Wendell Rodrigues",
          description: "",
          email: "wendell@ifce.edu.br",
          github: "",
          linkedin: "",
          sexo: "male"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.page-section {
  padding: 3rem 0;
}
// p a {
//   font-size: 0.9rem;
// }
.testimonials {
  .testimonial-item {
    max-width: 18rem;
    img {
      max-width: 9rem;
      box-shadow: 0px 5px 5px 0px #00000030;
    }
  }
}
</style>
